import { Injectable, InjectionToken, Inject, Injector} from '@angular/core';
import { UserService } from 'src/app/service/user-service';
import { PatientService } from 'src/app/service/patient-service';

import { User } from 'src/app/model/user';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { UserDao } from 'src/app/dao/user-dao';
import { PatientDao } from 'src/app/dao/patient-dao';
import { Patient } from 'src/app/model/patient';
import { UserWebResponse } from 'src/app/model/user-web-response';
import { DeviceSetting } from 'src/app/model/device-setting';
import { Data } from 'src/app/model/data';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = 'https://jsonplaceholder.typicode.com/posts/42';

@Injectable({
    providedIn: 'root'
})
export class PatientServiceImpl implements PatientService {
    patientDao: PatientDao = this.injector.get(PatientDao);
    constructor(private injector: Injector,private http:HttpClient) {}

    getPatient(): any {
        return this.patientDao.getPatient();
    }
    getDeviceSetting(patient): Observable<any> {
        return this.patientDao.getDeviceSetting(patient);
    }
    getPhoneTherapySetting(patient): Observable<any> {
        return this.patientDao.getPhoneTherapySetting(patient);
    }
    getSDKPatientURL(query_url): Observable<any> {
        return this.patientDao.getSDKPatientURL(query_url);
    }
    getPatientById(patient_id): Observable<any> {
        return this.patientDao.getPatientById(patient_id);
    }
    getReport(patient): Observable<any> {
        return this.patientDao.getReport(patient);
    }
    getClouddata(patient): Observable<any> {
        return this.patientDao.getClouddata(patient);
    }
    getTrenddata(patient): Observable<any> {
        return this.patientDao.getTrenddata(patient);
    }
    getTongue(patient): Observable<any> {
        return this.patientDao.getTongue(patient);
    }
    getEcg(patient,return_ecg_detail): Observable<any> {
        return this.patientDao.getEcg(patient,return_ecg_detail);
    }
    getPatientListCount(): Observable<any> {
        return this.patientDao.getPatientListCount();
    }
    getPatientList(): Observable<any> {
        return this.patientDao.getPatientList();
    }
    addPatient(patient): Observable<any> {
        console.log(patient);
        return this.patientDao.addPatient(patient);
    }
    getCloudUser(patient): Observable<any>{
        console.log(patient);
        return this.patientDao.getCloudUser(patient);
    }
    getCloudUserBySerial(patient): Observable<any>{
        console.log(patient);
        return this.patientDao.getCloudUserBySerial(patient);
    }
    getUser(patient): Observable<any>{
        console.log(patient);
        return this.patientDao.getUser(patient);
    }
    getChart(patient): Observable<any>{
        console.log(patient);
        return this.patientDao.getChart(patient);
    }
    editPatient(patient): Observable<any> {
        console.log(patient);
        return this.patientDao.editPatient(patient);
    }
    editDeviceSetting(devicesetting): Observable<any> {
        console.log(devicesetting);
        return this.patientDao.editDeviceSetting(devicesetting);
    }
    editMemonote(data): Observable<any> {
        return this.patientDao.editMemonote(data);
    }
    getUserAPI(user): Observable<any> {
        console.log(user);
        return this.patientDao.getUserAPI(user);
    }
    getTestUser(): Observable<any> {
        return this.patientDao.getTestUser();
    }
    login(user): Observable<any> {
        return this.patientDao.login(user).pipe(
            map(userWebResponse => {
                console.log(userWebResponse.success);
                if ( userWebResponse.success === true ) {
                    return new User(userWebResponse.data);
                }
                else {
                    return false;
                }
            })
        );
    }
    forgotpassword(user): Observable<any> {
        return this.patientDao.forgotpassword(user).pipe(
            map(userWebResponse => {
                console.log(userWebResponse.success);
                return  userWebResponse.success;
            })
        );
    }
    logout(user): Observable<any> {
        console.log(user);
        return this.patientDao.logout(user);
    }

    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
      
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
      
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
      
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }

}

// export const UserServiceProvider = new InjectionToken(
//     'UserServiceProvider',
//     { providedIn: 'root', factory: () => new UserServiceImpl(@Inject(ENV) private environment) }
// );
